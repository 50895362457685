import React from "react";

const PlayIcon = () => (
  <svg width="120px" height="120px" viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design-(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Initiatives-Detail-Copy" transform="translate(-515.000000, -1018.000000)">
            <g id="Group" transform="translate(525.000000, 1028.000000)">
                <polygon id="Path" fill="#F4F4F4" points="32 79 73 49.5 32 20"></polygon>
                <rect id="Rectangle" stroke="#F4F4F4" strokeWidth="10" x="-5" y="-5" width="110" height="110"></rect>
            </g>
        </g>
    </g>
</svg>
);

export default PlayIcon;
