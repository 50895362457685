import React from "react";
import LinkList from "./link-list";

import * as styles from "../styles/components/links-block.module.css";

function LinksBlock({ items }) {
  return (
    <div className={styles.root}>
      {items.map(({ _key, title, links }) => {
        return (
          <div className={styles.item} key={_key}>
            {title && <h6>{title}</h6>}
            {links && <LinkList items={links} />}
          </div>
        );
      })}
    </div>
  );
}

export default LinksBlock;
