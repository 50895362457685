import React from "react";

const ArrowLeftLgIcon = () => (
  <svg width="108px" height="79px" viewBox="0 0 108 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design-(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Initiatives-Detail" transform="translate(-73.000000, -4046.000000)">
            <g id="Gallery" transform="translate(-1.000000, 3369.000000)" stroke="#F4F4F4">
                <g id="Group" transform="translate(81.331303, 680.500000)">
                    <g transform="translate(47.500000, 36.000000) scale(-1, 1) translate(-47.500000, -36.000000) ">
                        <polyline id="Path-3-Copy" strokeWidth="9" points="57 0 95 36 57 72"></polyline>
                        <line x1="86" y1="35.6666667" x2="0" y2="35.6666667" id="Line" strokeWidth="10" strokeLinecap="square"></line>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default ArrowLeftLgIcon;
