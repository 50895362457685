import React from "react";
import Icon from "./icon";

import * as styles from "../styles/components/social-media.module.css";

function SocialMedia({ items }) {
  return (
    <ul className={styles.root}>
      {items.map(({ _key, title, link }) => {
        return (
          <li key={_key} className={styles.item}>
            {link && title && (
              <a href={link} target="_blank" rel="noreferrer" className={styles.link}>
                <Icon symbol={title.toLowerCase()} />
                <span>{title}</span>
              </a>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default SocialMedia;
