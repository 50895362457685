import { Link, StaticQuery, graphql } from "gatsby";
import React, { useState, useEffect } from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import Container from "./container";
import Helmet from 'react-helmet';

import * as styles from "../styles/components/header.module.css";

const query = graphql`
  query HeaderQuery {
    mainnav: allSanityNav(filter: { title: { eq: "Main Navigation" } }) {
      edges {
        node {
          navItems {
            _key
            text
            linkUrl
          }
        }
      }
    }
  }
`;

const Header = () => {

  // determined if page has scrolled and if the view is on mobile
  const [state, setState] = useState({
    scrolled: false,
  });

  const [showNav, setShowNav] = useState(false);

  function handleShowNav() {
    setShowNav(true);
  }

  function handleHideNav() {
    setShowNav(false);
  }

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.pageYOffset > 50;
      
      if (isScrolled !== state.scrolled) {
        setState({
          ...state,
          scrolled: !state.scrolled,
        });
      }
    };
    handleScroll();
    document.addEventListener('scroll', handleScroll);
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [state, state.scrolled]);

  return (
  <StaticQuery
    query={query}
    render={data => (
      <>
      <Helmet>
        <html className={showNav && styles.menuOpen} lang="en" />
      </Helmet>

      <header className={styles.root} id="header" data-scroll={state.scrolled ? "" : "top"}>

        <Container>
          <div className={styles.branding}>
            <Link to="/"><Icon symbol="logo" /></Link>
          </div>

          <nav className={cn(styles.nav, showNav && styles.active)}>
            {data && data.mainnav.edges.map(({node: mainnav}) => (
              <>
                {mainnav.navItems && (
                  <ul>
                    {mainnav.navItems.map(navItems => (
                      <li key={navItems._key}>
                        <Link to={`${navItems.linkUrl}`} onClick={showNav ? handleHideNav : handleShowNav}>{navItems.text}</Link> 
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ))}
          </nav>

          <button
            className={cn(styles.toggleNavButton, showNav && styles.active)}
            onClick={showNav ? handleHideNav : handleShowNav}
          >
            <span className={styles.icon}></span>
            <span className="hidden">Menu</span>
          </button>

          <div className={cn(styles.overlay, showNav && styles.active)} onClick={showNav ? handleHideNav : handleShowNav} onKeyDown={showNav ? handleHideNav : handleShowNav} role="button" tabIndex={0}><span className="hidden">Close Menu</span></div>
        </Container>

      </header>
      </>
    )}
  />
)};

export default Header;
