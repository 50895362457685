import React from "react";
import Icon from "./icon";

import * as styles from "../styles/components/project-list.module.css";

function ProjectList({ items }) {
  return (
    <ul className={styles.root}>
      {items.map(({ _key, title, excerpt, link }) => {
        return (
          <li className={styles.item} key={_key}>
            <div className="containerContent">
              {link 
                ? <h6 className={styles.title}><a href={link} target="_blank" rel="noreferrer">{title}<Icon symbol="external" /></a></h6>
                : <h6 className={styles.title}>{title}</h6>
              }
              {excerpt && <p className={styles.desc}>{excerpt}</p>}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default ProjectList;
