import React from "react";
import { Link } from "gatsby";

import * as styles from "../styles/components/link-list.module.css";

function LinkList({ items }) {
  return (
    <ul className={styles.root}>
      {items.map(({ _key, externalContent, text, linkUrl }) => {
        return (
          <>
          {linkUrl && (
            <li key={_key}>
              {externalContent
                ? <a href={linkUrl} target="_blank" rel="noreferrer">{text}</a>
                : <Link to={`${linkUrl}`}>{text}</Link>
              }
            </li>
          )}
          </>
        );
      })}
    </ul>
  );
}

export default LinkList;
