import { Link, StaticQuery, graphql } from "gatsby";
import LinksBlock from "./links-block";
import ProjectList from "./project-list";
import SocialMedia from "./social-media";
import Container from "./container";
import Icon from "./icon";
import PortableText from "./portableText";
import React, { useEffect, useRef } from "react";

import * as styles from "../styles/components/footer.module.css";

const query = graphql`
  query FooterQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      socialMedia {
        _key
        title
        link
      }
      footerLinks {
        _key
        title
        links {
          _key
          externalContent
          linkUrl
          text
        }
      }
      _rawFooterHeading
      footerProjects {
        _key
        title
        excerpt
        link
      }
    }
  }
`;

const Footer = () => {

  // Check footer height
  const targetElem = useRef(null);
  const styleElem = useRef(null);

  // change height on resize
  useEffect(() => {
    function updateHeight() {
      styleElem.current.style.paddingBottom = targetElem.current.clientHeight + "px";
    }

    let checkExist = setInterval(function() {
      if (targetElem.current) {
        updateHeight();
        clearInterval(checkExist);
      }
    }, 500); // check every 100ms

    window.addEventListener('resize', updateHeight);
    
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return(
  <StaticQuery
    query={query}
    render={data => (
      <footer className={styles.root}>
        <div className={styles.top}>
          <Container>
            <div className={styles.topCols}>
              <div className={styles.branding}>
                <Link to="/"><Icon symbol="logo" /></Link>
              </div>
              {data.site.footerLinks && (
                <div className={styles.linksBlock}>
                  <LinksBlock items={data.site.footerLinks} />
                </div>
              )}
              {data.site.socialMedia && (
                <div className={styles.socialMedia}>
                  <SocialMedia items={data.site.socialMedia} />
                </div>
              )}
            </div>
            {data.site.title && (
              <div className={styles.copyright}>
                Copyright &copy; {new Date().getFullYear()} {data.site.title}.<br /> All rights reserved.
              </div>
            )}
          </Container>
        </div>
        <div className={styles.bottom} ref={styleElem}>
          <div className={styles.bottomInner} ref={targetElem}>
            <Container>
              {data.site._rawFooterHeading && <h5 className={styles.heading}><PortableText blocks={data.site._rawFooterHeading} /></h5>}
              {data.site.footerProjects && <ProjectList items={data.site.footerProjects} />}
            </Container>
          </div>
        </div>
      </footer>
    )}
  />
)};

export default Footer;
