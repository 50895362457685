// extracted by mini-css-extract-plugin
export const root = "footer-module--root--fGnn6";
export const top = "footer-module--top--3ev83";
export const topCols = "footer-module--topCols--1OYs9";
export const branding = "footer-module--branding--Q4gDo";
export const socialMedia = "footer-module--socialMedia--1E9Mn";
export const linksBlock = "footer-module--linksBlock--23hd0";
export const copyright = "footer-module--copyright--1r5JW";
export const bottom = "footer-module--bottom--3S4Gr";
export const bottomInner = "footer-module--bottomInner--ev10H";
export const heading = "footer-module--heading--xddq5";