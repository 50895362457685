import React from "react";

const ExternalLgIcon = () => (
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="r" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Media" transform="translate(-600.000000, -3492.000000)">
            <g id="In-the-Media" transform="translate(0.000000, 3067.000000)" stroke="#F4F4F4" strokeWidth="3">
                <g id="Article-01" transform="translate(109.000000, 283.000000)">
                    <g id="Group" transform="translate(493.000000, 144.000000)">
                        <polyline id="Path-7" points="9.53005351 0 0 0 0 22 22 22 22 12.5710891"></polyline>
                        <polyline id="Path-5" points="14.08 0 22 0 22 7.92"></polyline>
                        <line x1="21.12" y1="0.88" x2="5.28" y2="16.72" id="Path-4"></line>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default ExternalLgIcon;
