import React from "react";

const PlusIcon = () => (
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design-(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="FAQ" transform="translate(-803.000000, -1210.000000)">
            <g id="Q2" transform="translate(40.000000, 1091.000000)" stroke="#242424" strokeWidth="3">
                <g id="Line" transform="translate(763.000000, 119.000000)">
                    <line x1="0" y1="12.5" x2="26" y2="12.5"></line>
                    <line x1="13" y1="0" x2="13" y2="26"></line>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default PlusIcon;
