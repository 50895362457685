import React from "react";
import { Figure } from "./Figure";
import BasePortableText from "@sanity/block-content-to-react";

const overrides = {
  normalp: props => <p className="normalp" {...props} />,
  normalpp: props => <p className="normalpp" {...props} />,
}

const serializers = {
  types: {
    /* eslint-disable-next-line react/display-name */
    block: props =>
      // Check if we have an override for the “style”
      overrides[props.node.style] 
        // if so, call the function and pass in the children, ignoring
        // the other unnecessary props
        ? overrides[props.node.style]({ children: props.children })

        // otherwise, fallback to the provided default with all props
        : BasePortableText.defaultSerializers.types.block(props),
    mainImage: Figure,
  },

  marks: {
    link: ({mark, children}) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark
      return blank ?
        <a href={href} target="_blank" rel="noreferrer">{children}</a>
        : <a href={href}>{children}</a>
    }
  }
};

export default serializers;
