import React from "react";

const MinusIcon = () => (
  <svg width="26px" height="3px" viewBox="0 0 26 3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design-(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="FAQ" transform="translate(-801.000000, -1435.000000)">
            <g id="Q3" transform="translate(40.000000, 1355.000000)" stroke="#242424" strokeWidth="3">
                <g id="Line" transform="translate(761.000000, 81.000000)">
                    <line x1="0" y1="0.5" x2="26" y2="0.5"></line>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default MinusIcon;
