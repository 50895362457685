import React from "react";

const ArrowRightIcon = () => (
  <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design-(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Homepage" transform="translate(-820.000000, -2189.000000)">
            <g id="Group" transform="translate(821.000000, 2190.000000)" stroke="#242424" strokeWidth="2">
                <g>
                    <polyline id="Path-3-Copy" points="9 0 15 6 9 12"></polyline>
                    <line x1="13" y1="6" x2="0" y2="6" id="Line" strokeLinecap="square"></line>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default ArrowRightIcon;
