import React from "react";
import ArrowDownIcon from "./arrow-down";
import ArrowLeftIcon from "./arrow-left";
import ArrowLeftLgIcon from "./arrow-left-lg";
import ArrowRightIcon from "./arrow-right";
import ArrowRightLgIcon from "./arrow-right-lg";
import ExternalIcon from "./external";
import ExternalLgIcon from "./external-lg";
import InstagramIcon from "./instagram";
import LinkedInIcon from "./linkedin";
import Logo from "./logo";
import MinusIcon from "./minus";
import PlayIcon from "./play";
import PlusIcon from "./plus";

function Icon(props) {
  switch (props.symbol) {
    case "arrow-down":
      return <ArrowDownIcon />;
    case "arrow-left":
      return <ArrowLeftIcon />;
    case "arrow-left-lg":
      return <ArrowLeftLgIcon />;
    case "arrow-right":
      return <ArrowRightIcon />;
    case "arrow-right-lg":
      return <ArrowRightLgIcon />;
    case "external":
      return <ExternalIcon />;
    case "external-lg":
      return <ExternalLgIcon />;
    case "instagram":
      return <InstagramIcon />;
    case "linkedin":
      return <LinkedInIcon />;
    case "logo":
      return <Logo />;
    case "minus":
      return <MinusIcon />;
    case "play":
      return <PlayIcon />;
    case "plus":
      return <PlusIcon />;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
