import React from "react";

const ArrowRightLgIcon = () => (
  <svg width="107px" height="80px" viewBox="0 0 107 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design-(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Homepage" transform="translate(-850.000000, -9803.000000)">
            <g id="Group" transform="translate(855.000000, 9807.000000)" stroke="#F4F4F4">
                <polyline id="Path-3-Copy" strokeWidth="9" points="57 0 95 36 57 72"></polyline>
                <line x1="86" y1="35.6666667" x2="0" y2="35.6666667" id="Line" strokeWidth="10" strokeLinecap="square"></line>
            </g>
        </g>
    </g>
</svg>
);

export default ArrowRightLgIcon;
