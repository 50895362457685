import React from "react";

const Logo = () => (
  <svg width="313px" height="42px" viewBox="0 0 313 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Nav-Bar-(Blue)" transform="translate(-40.000000, -20.000000)" fill="#F4F4F4">
            <g id="Group" transform="translate(40.000000, 20.000000)">
                <g id="Fill-1">
                    <polygon points="42.159 0 42.159 33.558 33.6735 33.558 33.6735 16.5225 26.3625 16.5225 26.3625 33.558 17.8395 33.558 17.8395 0 10.5195 0 10.5195 16.5315 0 16.5315 0 24.1005 10.272 24.1005 10.272 40.893 49.467 40.893 49.467 24.1005 60 24.1005 60 16.5315 49.467 16.5315 49.467 0"></polygon>
                </g>
                <text id="Wilson-5-Foundation" fontFamily="Helvetica" fontSize="25" fontWeight="normal">
                    <tspan x="80" y="28">Wilson 5 Foundation</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>
);

export default Logo;
