import React from "react";

const ArrowDownIcon = () => (
  <svg width="15px" height="18px" viewBox="0 0 15 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design-(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Homepage" transform="translate(-224.000000, -867.000000)">
            <g id="Blue-Bar" transform="translate(0.000000, 852.000000)" stroke="#F4F4F4" strokeWidth="2">
                <g id="Group" transform="translate(225.500000, 16.500000)">
                    <polyline id="Path-3-Copy" transform="translate(6.000000, 12.000000) rotate(90.000000) translate(-6.000000, -12.000000) " points="3 6 9 12 3 18"></polyline>
                    <line x1="12.5" y1="6.5" x2="-0.5" y2="6.5" id="Line" strokeLinecap="square" transform="translate(6.000000, 6.500000) rotate(90.000000) translate(-6.000000, -6.500000) "></line>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default ArrowDownIcon;
