import React from "react";

const LinkedInIcon = () => (
  <svg width="22px" height="23px" viewBox="0 0 22 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Footer" transform="translate(-40.000000, -300.000000)" fill="#F4F4F4" fillRule="nonzero">
            <g id="Footer-Copy">
                <path d="M60.4285714,322.010831 C61.292838,322.010831 62,321.298778 62,320.42468 L62,301.596982 C62,300.722885 61.292838,300.010831 60.4285714,300.010831 L41.566537,300.010831 C40.7022226,300.010831 40,300.722885 40,301.596982 L40,320.42468 C40,321.298778 40.7022226,322.010831 41.566537,322.010831 L60.4285714,322.010831 Z M45.0187596,306.934938 C43.972787,306.934938 43.1281346,306.085394 43.1281346,305.044313 C43.1281346,304.003232 43.972787,303.153688 45.0187596,303.153688 C46.0598406,303.153688 46.9093846,304.003232 46.9093846,305.044313 C46.9093846,306.090286 46.0647321,306.934938 45.0187596,306.934938 Z M51.9575893,318.867974 L48.6968558,318.867974 L48.6968558,308.368876 L51.8249904,308.368876 L51.8249904,309.802815 L51.869206,309.802815 C52.3062308,308.977776 53.3718654,308.108618 54.9580165,308.108618 C58.2580261,308.108618 58.8718654,310.284055 58.8718654,313.112607 L58.8718654,318.867974 L55.6111799,318.867974 L55.6111799,313.760831 C55.6111799,312.542984 55.5866263,310.976447 53.9169835,310.976447 C52.2178476,310.976447 51.9575893,312.302339 51.9575893,313.672448 L51.9575893,318.867974 Z M46.6540179,318.867974 L43.3883929,318.867974 L43.3883929,308.368876 L46.6540179,308.368876 L46.6540179,318.867974 Z" id="LinkedIn"></path>
            </g>
        </g>
    </g>
</svg>
);

export default LinkedInIcon;
