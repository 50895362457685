import React from "react";
import Header from "./header";
import Main from "./main";
import Footer from "./footer";
import "../styles/common/common.css";

const Layout = ({ children }) => (
  <>
    <Header />
    <Main>{children}</Main>
    <Footer />
  </>
);

export default Layout;
