import React from "react";

const ExternalIcon = () => (
  <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Footer" transform="translate(-184.000000, -699.000000)" stroke="#242424" strokeWidth="2">
            <g id="Group" transform="translate(185.000000, 700.000000)">
                <polyline id="Path-7" points="4.76502676 0 0 0 0 11 11 11 11 6.28554456"></polyline>
                <polyline id="Path-5" points="7 0 11 0 11 4"></polyline>
                <line x1="11" y1="0" x2="3" y2="8" id="Path-4"></line>
            </g>
        </g>
    </g>
</svg>
);

export default ExternalIcon;
